import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const DebtRepaymentPlanPage = () => {
    const article = {
        id: 'a62f9d76-7a38-59f3-b723-46ccd527e330',
        title: 'Creating a Debt Repayment Plan',
        slug: '/debt-repayment-plan/',
        date: '2018-03-23T16:33:48.000Z',
        modified: '2021-11-04T19:07:33.000Z',
        excerpt: 'Learn everything you need to know to create a debt repayment plan. Find out how to contact a credit counselor if you need assistance to create a debt management plan.',
        featured_image: {
            source_url: '/media/debt-repayment-plan.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 11,
        content: (
            <>
                <p>It&rsquo;s easy to feel overwhelmed when you have multiple debts and no plan to pay them off.</p>
                <p>Accessing and using credit is incredibly easy.</p>
                <p>
                    When one
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-repayment-plan&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    gets maxed out, another one always seems to be available.
                </p>
                <p>If you just maintain minimum payments, it could take 30 years to get out of multiple debts.</p>
                <p>Long-term debt can cripple your cash flow with high monthly loan repayments.</p>
                <p>
                    You&rsquo;ll end up paying thousands in interest while struggling to pay down the principle on the loans or
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-repayment-plan&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    .
                </p>
                <p>The average American adult has $4,717 in credit card debt.</p>
                <p>
                    The average
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=debt-repayment-plan&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    interest rate (APR) is 15%.
                </p>
                <p>If the minimum payment of $189 was made every month, it would take 10 years and one month to erase just that debt.</p>
                <p>That&rsquo;s crazy.</p>
                <p>The total payments for the original $4,717 loan would be $22,869 because of interest.</p>
                <p>If you&rsquo;re starting to swim in debt, you need to act now to come up with a plan to pay it off.</p>
                <p>Fortunately, there are multiple options and strategies for creating and sticking to a debt repayment plan.</p>
                <p>You can empower yourself to become debt-free sooner by looking over these strategies and picking the one suitable for your situation.</p>
                <p>As your debt situation gets more serious, the options change.</p>
                <p>For instance, if you&rsquo;ve failed–on your own–to succeed at a debt repayment plan in the past, some additional support might be required in the form of a credit counselor.</p>
                <p>
                    <LazyLoadImage alt="Percentage of U.S. Families With Debt" src="/media/percentage-of-u-s-families-with-debt-1.png" />
                </p>
                <p>It&rsquo;s ok to ask for help when you need it.</p>
                <p>
                    No matter how you feel, if you&rsquo;re starting to see the early
                    {' '}
                    <a href="https://www.debtconsolidation.com/bankruptcy/">signs you might be heading towards bankruptcy</a>
                    , it&rsquo;s time to act.
                </p>
                <p>
                    Before you consider taking out more credit to pay for your existing debt (
                    <a href="https://www.debtconsolidation.com/loans/">for example, a debt consolidation loan</a>
                    {' '}
                    like a home equity loan), see if you can pay it off with the income you already have.
                </p>
                <p>Take control of your debt by choosing one of the debt repayment planning options recommended by the experts.</p>
                <h2 id="know-how-much-debt-you-have">Know How Much Debt You Have</h2>
                <h3>By understanding your debt, you can create an accurate debt repayment plan</h3>
                <p>You can&rsquo;t create a debt repayment plan without knowing exactly what debt you are planning to repay.</p>
                <p>
                    <strong>Get a grip.</strong>
                    {' '}
                    Grasping what you owe and what you&rsquo;re being charged is the first step.
                </p>
                <p>You might not fully appreciate how much you are in debt until you see it all listed in one place.</p>
                <p>The data you collect about your debts will be important for calculating precise goals, timelines, and repayment schedules.</p>
                <h3>Tally all your debts and the interest you&rsquo;re being charged</h3>
                <p>
                    Take an inventory of your credit card balances, medical bills, student and
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=debt-repayment-plan&amp;sub2=personal-loans" rel="noopener noreferrer" target="_blank">personal loans</a>
                    , and any other form of unsecured debt you&rsquo;re paying off.
                </p>
                <p>Unsecured debt is debt not guaranteed by an asset like your house or a car.</p>
                <p>Then identify the interest rates you&rsquo;re paying on each debt.</p>
                <p>You should also collect information like the minimum monthly payment on each debt.</p>
                <p>
                    <strong>Rank your debts from smallest to largest.</strong>
                    {' '}
                    For one list, start with the lowest balance first and list down to the highest balance.
                </p>
                <p>
                    <strong>Compare the interest rates.</strong>
                    {' '}
                    For the second list, start with the debt that has the highest rate of interest, and list down to the lowest interest rate.
                </p>
                <h3>Here&rsquo;s a pretend list of debt and the interest charged</h3>
                <p>Let&rsquo;s take a situation where four debts need to be repaid, as an example.</p>
                <ul className="list">

                    <li>One is a credit card with a balance of $5,000 and an APR of 17.99%.</li>

                    <li>
                        The second is a
                        {' '}
                        <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=debt-repayment-plan&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                        {' '}
                        with a balance of $10,000 and an interest rate of 4%.
                    </li>

                    <li>Third is another credit card with a balance of $3,000 and an APR of 15.99%.</li>

                    <li>And fourth is a car loan with a balance of $8,000 and an APR of 3.5%.</li>
                </ul>
                <p>
                    <strong>How your debts rank depends on what we&rsquo;re looking at.</strong>
                    {' '}
                    The list order will change depending on what criteria you rank them by.
                </p>
                <p>
                    If you&rsquo;re ranking lowest balance first to highest balance, the $3,000 credit card is on top and the $10,000
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=debt-repayment-plan&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                    {' '}
                    is ranked at the bottom.
                </p>
                <p>When you&rsquo;re ranking the debts from the highest rate of interest down to the lowest interest rate, the $5,000 credit card account at 17.99% is first and the car loan being charged 3.5% is last.</p>
                <p>
                    <strong>Keep your lists handy, you&rsquo;re going to need them.</strong>
                    {' '}
                    These rankings are going to be important when we start calculating different repayment plan options.
                </p>
                <h2 id="budgeting-is-key-to-success">Budgeting Is Key to Success</h2>
                <h3>A successful debt repayment plan starts and ends with budgeting</h3>
                <p>Looking at your existing budget when you&rsquo;re about to create a debt repayment plan is vital.</p>
                <p>
                    <strong>Where does all the money go?</strong>
                    {' '}
                    Calculating where your money goes each month gives you a grasp of what you spend on right now.
                </p>
                <p>
                    <strong>Get real about your spending.</strong>
                    {' '}
                    Your current spending behavior is what got you into debt in the first place.
                </p>
                <p>It&rsquo;s important to get an honest understanding of how you spend your money.</p>
                <p>Creating a new budget focused on repaying debt will involve changing your existing habits.</p>
                <h3>Calculate your current budget to prepare for a new debt repayment plan budget</h3>
                <p>There are three steps you need to follow to accurately calculate your current monthly budget.</p>
                <p>
                    <strong>1. How much is coming in?</strong>
                    {' '}
                    First, list all your sources of income for an average month.
                </p>
                <p>Include your salary, government benefits, rental property income, and any other revenue you generate on a monthly basis.</p>
                <p>
                    <strong>2. Add up all the regular bills.</strong>
                    {' '}
                    Second, itemize your monthly expenses.
                </p>
                <p>
                    Include monthly bills such as rent,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=debt-repayment-plan&amp;sub2=insurance" rel="noopener noreferrer" target="_blank">insurance</a>
                    {' '}
                    premiums, utility bills, and property taxes.
                </p>
                <p>
                    <strong>3. Don&rsquo;t forget the debts.</strong>
                    {' '}
                    Third, include the normal monthly payments you currently submit for each of our debts.
                </p>
                <p>
                    <strong>Figure out what you spend.</strong>
                    {' '}
                    Also include average personal monthly expenses such as groceries, gas, alcohol, entertainment, transportation, and any other common expenditure.
                </p>
                <p>
                    <LazyLoadImage alt="Average Expenditures Per Consumer in 2016" src="/media/average-expenditures-per-consumer-in-2016-2.png" />
                </p>
                <p>
                    <strong>Prepare to have your eyes opened.</strong>
                    {' '}
                    Adding up your income and comparing it to your expenses will likely be an eye-opening experience.
                </p>
                <p>Debt starts to pile up in multiple forms when there isn&rsquo;t enough income to meet the monthly expenses.</p>
                <p>
                    <strong>You can&rsquo;t borrow your way out of it.</strong>
                    {' '}
                    When a personal budget deficit becomes a regular occurrence, often the impulse is to borrow to make up for it.
                </p>
                <p>The reality is, somewhere down the road it&rsquo;s going to have to be repaid, probably with interest.</p>
                <h3>Here&rsquo;s a hypothetical budget</h3>
                <p>Let&rsquo;s say you&rsquo;re a single person bringing in a respectable $4,000 in income a month.</p>
                <p>
                    <strong>Some living expenses you can&rsquo;t avoid.</strong>
                    {' '}
                    For your expenses, $2,000 a month goes to rent and utilities.
                </p>
                <p>$200 goes to cell phone and internet bills.</p>
                <p>$450 goes to groceries.</p>
                <p>Every month you spend $200 on gas.</p>
                <p>
                    <strong>The fun stuff costs.</strong>
                    {' '}
                    You also spend $250 per month eating at restaurants and take-out, $200 a month on beer and wine, and $150 on entertainment.
                </p>
                <p>
                    <strong>Paying the minimum on debts.</strong>
                    {' '}
                    Finally, if we use the example of the four debts in the previous assessment section, you pay a minimum of $683 a month on the $26,000 total owed on all four debts.
                </p>
                <p>
                    <strong>What goes out is more than what comes in.</strong>
                    {' '}
                    Your budget has a total income of $4,000 and total expenses of $4,133.
                </p>
                <p>Unexpected expenses like vet bills and car repairs aren&rsquo;t even included in those numbers.</p>
                <p>Now you understand how you got into debt.</p>
                <p>You also have all the information you need to budget your way out of it with a debt repayment plan.</p>
                <h2 id="two-proven-techniques-to-pay-off-debt">Two Proven Techniques to Pay Off Debt</h2>
                <h3>You can start paying it off with the debt &lsquo;snowball&rsquo; repayment plan</h3>
                <p>
                    As long as your debt isn&rsquo;t crippling and your income is steady, there&rsquo;s a good chance you can
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-management/">escape it just by planning correctly</a>
                    .
                </p>
                <p>
                    <strong>Do it yourself.</strong>
                    {' '}
                    You don&rsquo;t need loans, or counselors, or debt settlement agencies.
                </p>
                <p>Create a personalized plan designed to deliberately chip away at your debt as efficiently as possible.</p>
                <p>One well-known model for attacking debt is the debt snowball method.</p>
                <h3>Throw snowballs at the smallest debts first</h3>
                <p>The debt snowball method involves paying off the debts with the lowest balances first and working your way up through the rest of them.</p>
                <p>
                    <strong>Time to pull out the lists.</strong>
                    {' '}
                    Remember when we told you to hang on to those lists where you ranked your debts?
                </p>
                <p>Now is the time to pull out the one ranking them from lowest balance to highest.</p>
                <p>
                    <strong>Make some tough choices.</strong>
                    {' '}
                    It&rsquo;s also time to look at your existing budget and ask yourself if you can redirect any money to paying off the debt each month.
                </p>
                <p>According to the examples we&rsquo;ve been using, your disposable income (restaurants, alcohol, and entertainment) totaled $600 a month.</p>
                <p>
                    <strong>It&rsquo;s time to tighten the belt.</strong>
                    {' '}
                    In order for a debt snowball repayment plan to work, you need to free up as much money as possible to put towards monthly debt payment.
                </p>
                <h3>Here&rsquo;s how the snowball method works</h3>
                <p>
                    <strong>Cut the fun budget in half.</strong>
                    {' '}
                    Let&rsquo;s take $300 a month from the disposable income budget and put it towards paying the debts.
                </p>
                <p>The total debt repayment budget jumps from the minimum of $683 a month to $983. The extra $300 is your initial &quot;debt snowball.&quot;</p>
                <p>
                    <strong>The debt snowball method gets you debt-free in less 2.5 years.</strong>
                    {' '}
                    By paying the extra $300 on the smallest debt first (the $3,000 debt on the second credit card) it will be paid off in eight months.
                </p>
                <p>
                    <strong>The snowball gets bigger as debts disappear.</strong>
                    {' '}
                    You then take what you had been paying monthly on the smallest loan, and roll it into paying off the second-smallest balance (the $5,000 credit card).
                </p>
                <p>It will be gone in 15 months.</p>
                <p>The third smallest debt ($8,000 car loan) will be gone in 22 months.</p>
                <p>
                    <strong>Good-bye debt.</strong>
                    {' '}
                    In 29 months all your debt, including the $10,000
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=debt-repayment-plan&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                    , will have disappeared.
                </p>
                <p>Over that time, you&rsquo;ll have paid a total of $1,986.64 in interest on what was originally a $26,000 debt.</p>
                <h2 id="or-bury-your-debt-just-as-fast-with-an-avalanche-debt-repayment-plan">Or, bury your debt just as fast with an &lsquo;avalanche&rsquo; debt repayment plan</h2>
                <p>Another popular model for debt repayment planning pays off the debts with the highest interest rates first.</p>
                <p>
                    <strong>It all starts with another snowball.</strong>
                    {' '}
                    The avalanche method also requires you to make more than the minimum payments on your debts.
                </p>
                <p>The difference between the minimum payments and the budget you create is once again your &quot;snowball.&quot;</p>
                <h3>Turn a snowball into an avalanche by getting rid of the high-interest debts first</h3>
                <p>Now is the time to pull out the second-ranked list of your debts, the one ranking them from highest interest rate to lowest interest rate.</p>
                <p>
                    <strong>The snowball grows into an avalanche as each debt is erased.</strong>
                    {' '}
                    By throwing the snowball at the highest-interest accounts first, they disappear first, and less interest is paid overall.
                </p>
                <p>
                    <strong>Be debt-free in 29 months with lower overall interest.</strong>
                    {' '}
                    Using our example, the first $5,000 credit card debt (being charged a whopping 17.99% APR) would be the first debt to get the extra $300 a month.
                </p>
                <p>It would get paid off in full in 11 months.</p>
                <p>
                    <strong>The avalanche builds power.</strong>
                    {' '}
                    The debt with the second highest interest rate (the $3,000 credit card at 15.99% APR) would then get the entire budget previously spent on it and the first debt.
                </p>
                <p>It would only take an extra four months to pay that one off.</p>
                <p>Next would come the $10,000 student loan being charged 4% interest.</p>
                <p>It would be paid off 25 months after the plan began.</p>
                <p>
                    <LazyLoadImage alt="2017 Student Loan Debt" src="/media/2017-student-loan-debt-3.png" />
                </p>
                <p>
                    <strong>Same amount of time as the snowball model.</strong>
                    {' '}
                    Finally, the entire $983 a month would go towards what&rsquo;s left on the $8,000 car loan (3.5% APR).
                </p>
                <p>It would be wiped out in 29 months (exactly the same amount of total time as the snowball method).</p>
                <p>
                    <strong>Take yourself out to dinner with the interest saved.</strong>
                    {' '}
                    The total interest you&rsquo;d pay with the avalanche method would be $1,933.42, a cool $53.22 less than the snowball method.
                </p>
                <h2 id="theres-help-if-you-need-it">There&rsquo;s Help if You Need It</h2>
                <h3>Visit a credit counselor if you can&rsquo;t do it yourself</h3>
                <p>Both of the structured debt repayment plans we outlined above are fantastic solutions.</p>
                <p>That is, as long as you have enough disposable income to redirect towards paying off your debt.</p>
                <p>
                    <strong>Call if you&rsquo;re just treading water.</strong>
                    {' '}
                    If a person finds they can barely muster the minimum payments every month and no end of debt is in sight, it&rsquo;s a good idea to
                    {' '}
                    <a href="https://www.debtconsolidation.com/credit-counseling/">contact a credit counselor</a>
                    .
                </p>
                <p>Credit counseling is usually a non-profit service.</p>
                <p>A credit counselor can do a couple of things.</p>
                <p>
                    <strong>Support for changing behaviors.</strong>
                    {' '}
                    No plan for getting out of debt is effective unless it addresses what caused the debt in the first place.
                </p>
                <p>A credit counselor can help you change your habits, so you can make the changes you need to get out of debt and stay there.</p>
                <p>They can help you with budgeting and train you to use credit wisely.</p>
                <p>A credit counselor&rsquo;s role includes teaching you about finances including credit and debt.</p>
                <p>
                    <strong>Reduce interest, payments, and fees with a Debt Management Plan.</strong>
                    {' '}
                    The other important function a credit counselor does is to create a debt management plan.
                </p>
                <p>
                    <strong>Going to bat for people in debt.</strong>
                    {' '}
                    The credit counselor will reach out to your creditors on your behalf.
                </p>
                <p>They will negotiate lower interest rates, lower monthly payments, and waived late fees.</p>
                <p>
                    <strong>Working together to make a plan.</strong>
                    {' '}
                    Credit counselors then work with clients to create a formal Debt Management Plan.
                </p>
                <p>
                    <strong>Income-based repayment.</strong>
                    {' '}
                    They agree upon a single affordable monthly payment based on income.
                </p>
                <p>The debtor will send the monthly payment to the credit counseling agency each month.</p>
                <p>The counseling agency pays off the creditors at the reduced negotiated rates.</p>
                <h3>Here&rsquo;s how a credit counselor works</h3>
                <p>A person comes to a credit counseling agency with $40,000 a year in income and a combined debt of $25,000 in credit cards, loans, and medical bills.</p>
                <p>The average interest rate being charged is 12.99%.</p>
                <p>
                    <strong>Impossible monthly minimum payments.</strong>
                    {' '}
                    The minimum monthly payments are a knee-buckling $750.
                </p>
                <p>
                    <strong>Cut the interest.</strong>
                    {' '}
                    The credit counselor contacts the creditors and negotiates the interest rate down to 7.99%.
                </p>
                <p>
                    <strong>Slash the monthly payment.</strong>
                    {' '}
                    Based on their $40,000 annual income, with an income-based repayment plan, the monthly payment the client pays through the credit agency is $400.
                </p>
                <p>At the original rates and payment schedule, it would have taken 43 months (3.5 years) to pay the entire debt off. $6,108.23 would have been paid in total interest.</p>
                <p>The only problem was, the $750-a-month payback schedule was unaffordable with a salary of $40,000 a year.</p>
                <p>
                    <strong>Longer repayment period doesn&rsquo;t cost much more.</strong>
                    {' '}
                    With the new rates and payments negotiated by the credit counselor, the debtor will take much longer — 82 months, or just under 7 years — to pay off the debt.
                </p>
                <p>
                    <LazyLoadImage alt="Annual Credit Counselor Salary in 2018" src="/media/annual-credit-counselor-salary-in-2018-4.png" />
                </p>
                <p>The interest paid over the 82 months will total $7,332.50.</p>
                <p>
                    <strong>More time, less interest, and more affordable payments.</strong>
                    {' '}
                    The repayment period was almost doubled, and the difference in the amount of interest paid was only $1,224.27 more than before.
                </p>
                <p>Instead of trying to afford $750 a month on a salary of $40,000 a year, the monthly income-based repayment schedule was $400 a month.</p>
                <h3>Debt repayment plans can help avoid debt settlement agencies and bankruptcy</h3>
                <p>Debt repayment planning needs to happen as soon as you realize you will never get out of debt with your current repayment schedule.</p>
                <p>It takes some soul-searching to look honestly at your habits and expenses.</p>
                <p>Making changes to your behavior can be much more challenging than calculating the repayment plan with the lowest interest.</p>
                <p>
                    A credit counselor can act as the final line of defense, advising you on
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-settlement/">how a debt settlement agency can provide debt relief</a>
                    .
                </p>
                <p>
                    They can also tell you
                    {' '}
                    <a href="https://www.debtconsolidation.com/bankruptcy/">when bankruptcy makes sense</a>
                    .
                </p>
                <p>Do you have any advice or tips for debt repayment planning?</p>
                <p>What&rsquo;s your experience?</p>
                <p>Share with us in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default DebtRepaymentPlanPage;
